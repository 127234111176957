<template>
    <div>
        <h1>
            This is the ParkBliss project demo for CS 522 @ University of Illinois Chicago Spring 2022
        </h1>
        <h2>
            By
        </h2>
        <h3>
            Linfeng Li, Claudio Segura
<!--            , Ting-Shao Lee-->
        </h3>
        <Menubar :model="items"/>
        <router-view/>
        <AboutUs class="about-box"/>
    </div>
</template>

<script>
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import AboutUs from "@/components/AboutUs";
// import BlankLayout from "@/components/BlankLayout";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAgjCiX1GNqVCuPsMNExIOCkGzZ9PnuYLg",
    authDomain: "cs522-demo.firebaseapp.com",
    projectId: "cs522-demo",
    storageBucket: "cs522-demo.appspot.com",
    messagingSenderId: "588121989895",
    appId: "1:588121989895:web:d7f4e350858a633cea5ba2",
    measurementId: "G-TCB9CQ9LLV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
getAnalytics(app);
export default {
    name: 'App',
    components: {
        AboutUs
        // BlankLayout
    },
    data() {
        return {
            items: [
                {
                    label:'Motivation',
                    icon:'pi pi-fw pi-file',
                    to: "/motivation"
                },
                {
                    label:'Design Challenges',
                    icon:'pi pi-fw pi-file',
                    to: "/designChallenges"
                },
                {
                    label:'Formative Evaluation',
                    icon:'pi pi-fw pi-check',
                    to: "/formativeEval"
                },
                {
                    label:'Requirements',
                    icon:'pi pi-fw pi-user',
                    to: "/requirements"
                },
                {
                    label:'Prototypes',
                    icon:'pi pi-fw pi-mobile',
                    to: "/prototypes"
                },
                {
                    label:'Demo',
                    icon:'pi pi-fw pi-play',
                    to: "/demo"
                }
            ]
        }
    }
}
</script>

<style>


html {
    background: url("./assets/Parking.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {

}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffa100;
    margin-top: 60px;
}


</style>
