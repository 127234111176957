/**
* Created by Linfeng Li on 4/13/22
* University of Illinois Chicago
*/

<template>
    <div class="eval-box">
        <div class="eval-row">

            <Card class="eval-card">
                <template #title>
                    Task 1 Record parking
                </template>
                <template #content>
                    You just parked your car in the parking garage.

                    Please save the parking spot using the app and the smart tag.

                </template>
            </Card>
            <img src="../assets/walkthrough1.png"/>

            <Divider/>

            <Card class="eval-card">
                <template #title>
                    Task 2 Retrieve parking
                </template>
                <template #content>
                    Today, you drove to school and parked in a nearby parking lot. When you left you saved
                    the location through “Park blizz.” After class, you want to pick up your car and drive
                    home but you do not remember
                    where you parked and want to get information, like parking location, parking number,
                    directions, through the app.

                </template>
            </Card>
            <img src="../assets/walkthrough2.png"/>

            <Divider/>

            <Card class="eval-card">
                <template #title>
                    Task 3 Share parking
                </template>
                <template #content>
                    This morning you and your friend Claudio commuted to UIC together. After parking your car you
                    recorded your parking location on your park bliss app. At this moment you want to share the
                    location with Claudio using the app. Assume that Claudio’s contact is saved on your phone
                    and the app will pre-populate his name as you type it.
                </template>
            </Card>
            <img src="../assets/walkthrough3.png"/>
        </div>

    </div>
</template>

<script>
export default {
    name: "FormativeEval"
}
</script>

<style scoped>

.eval-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
}

.eval-row {
    width: auto;
}

.eval-card {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}

img {
    width: 80%;
    height: auto;
}

</style>