/**
* Created by Linfeng Li on 4/13/22
* University of Illinois Chicago
*/

<template>
    <div>
        <div class="challenges">
            <img src="../assets/designChallenges.png"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "DesignChallenges"
}
</script>

<style scoped>

.challenges {
    margin-top: 20px;
}

img {
    width: 80%;
    height: auto;
}
</style>