import {createApp} from 'vue'
import App from './App.vue'
import 'primevue/resources/themes/vela-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css'
import Prototypes from "@/components/Prototypes";
import Requirements from "@/components/Requirements";
import FormativeEval from "@/components/FormativeEval";
import Demo from "@/components/Demo";
import DesignChallenges from "@/components/DesignChallenges";
import PrimeVue from 'primevue/config';

import {createRouter, createWebHashHistory} from 'vue-router'
import BlankLayout from "@/components/BlankLayout";
import Fieldset from 'primevue/fieldset';

import Menubar from 'primevue/menubar';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Image from 'primevue/image';
import Motivation from "@/components/Motivation";

const app = createApp(App);

const routes = [
    {
        path: '/',
        component: BlankLayout,
        redirect: "motivation",
        children: [
            {
                path: 'motivation', component: Motivation
            },
            {
                path: 'designChallenges', component: DesignChallenges
            },
            {
                path: 'FormativeEval', component: FormativeEval
            },
            {
                path: 'Requirements', component: Requirements
            },
            {
                path: 'Prototypes', component: Prototypes
            },
            {
                path: 'Demo', component: Demo
            },
        ]
    }
];

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})
app.use(router)

app.use(PrimeVue);

// eslint-disable-next-line vue/multi-word-component-names
app.component('Menubar', Menubar);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Fieldset', Fieldset);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Card', Card);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Button', Button);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Divider', Divider);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Image', Image);
app.mount('#app')
