/**
* Created by Linfeng Li on 4/12/22
* University of Illinois Chicago
*/

<template>
    <h2>
        Prototypes
    </h2>
    <div>
        <img src="../assets/prototype1.png"/>
    </div>
    <div>

        <img src="../assets/prototype2.png"/>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Prototypes"
}
</script>

<style scoped>

img {
    width: 60%;
    height: auto;
}

</style>