/**
* Created by Linfeng Li on 4/21/22
* University of Illinois Chicago
*/

<template>
    <div class="about-box">
        <h2>
            About Us
        </h2>
        <div class="linkedin-profile">

            <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="dark"
                 data-type="HORIZONTAL" data-vanity="linfengl" data-version="v1"><a
                class="badge-base__link LI-simple-link"
                href="https://www.linkedin.com/in/linfengl?trk=profile-badge">Linfeng
                Li</a></div>


            <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="dark"
                 data-type="HORIZONTAL" data-vanity="clausji" data-version="v1"><a
                class="badge-base__link LI-simple-link"
                href="https://www.linkedin.com/in/clausji?trk=profile-badge">Claudio
                Jimenez</a></div>

<!--            <div class="badge-base LI-profile-badge" data-locale="zh_TW" data-size="large" data-theme="dark"-->
<!--                 data-type="HORIZONTAL" data-vanity="tingshaolee" data-version="v1"><a-->
<!--                class="badge-base__link LI-simple-link"-->
<!--                href="https://www.linkedin.com/in/tingshaolee?trk=profile-badge">Ting-Shao-->
<!--                Lee</a></div>-->
        </div>
    </div>

</template>

<script>

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "AboutUs",
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://platform.linkedin.com/badges/js/profile.js')
        document.head.appendChild(recaptchaScript)
    },
}
</script>

<style scoped>

.about-box {
    display: flex;
    flex-direction: column;
    background-color: #1f2d40;
    margin-top: 15%;
}

.linkedin-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
    overflow: scroll;
    bottom: 0;

}

.badge-base {

}

a {
    color: #ffa100;
}
</style>