/**
* Created by Linfeng Li on 4/13/22
* University of Illinois Chicago
*/

<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
export default {
    name: "BlankLayout"
}
</script>

<style scoped>

</style>