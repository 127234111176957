/**
* Created by Linfeng Li on 4/13/22
* University of Illinois Chicago
*/

<template>
    <div class="requirements-box">
        <div class="requirements-box-row">
            <Fieldset class="dropdown-item" legend="User Requirements" >
                <ul>
                    <li>
                        <h4>
                            Users must have access to the internet when setting up the service for the first time.
                        </h4>

                    </li>
                    <li>
                        <h4>
                            Users should be familiar with how to use the NFC interface.
                        </h4>

                    </li>
                    <li>
                        <h4>
                            Users must have cellular connection in order to share their parking location.
                        </h4>

                    </li>
                    <li>
                        <h4>
                            Users must be able to raise and hold the mobile device to shoulder level.
                        </h4>

                    </li>
                    <li>
                        <h4>
                            Users must know if the parking lot uses the NFC parking system.
                        </h4>

                    </li>
                    <li>
                        <h4>
                            Users must have a mobile device that has near-field communication (NFC) technology enabled.
                        </h4>

                    </li>
                </ul>
            </Fieldset>

            <Fieldset class="dropdown-item" legend="Functional Requirements">
                <ul>
                    <li>
                        <h4>
                            The system must be able to record the parking location.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must allow users to access their parking location.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must provide methods for users to share parking information.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must be able to provide visual information to help users locate their car.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must allow users to view their parking history.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must allow users to add and delete parking information.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must allow users to add and delete car profiles.
                        </h4>
                    </li>

                </ul>
            </Fieldset>

            <Fieldset class="dropdown-item" legend="Specific Usability Requirements">
                <ul>
                    <li>
                        <h4>
                            Layouts, services, and presentations should be logically organized when displayed
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system should provide multimodal feedback to the user.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Navigation should be possible between any layout, service, or presentation.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Parking location information should be easy to read and understand.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Parking location mapping should be easily accessible.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Finger occlusion should be minimized.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Steps to access a visual map of the parking lot should be minimized.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Steps to record parking location should be minimized.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Steps to share parking location should be minimized.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must be easy to learn for both novices and users with experience from similar systems.
                        </h4>
                    </li>
                </ul>
            </Fieldset>

            <Fieldset  class="dropdown-item" legend="General Usability Requirements">
                <ul>
                    <li>
                        <h4>
                            The application should be visually appealing on a mobile device.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The user must understand what the system does.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The number of required taps to access the main features should be minimized.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            Screen space for the map page should be maximized.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The user interface should be consistent internally and externally.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The application should always keep users informed about the current system status, through appropriate feedback.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            User action should be recoverable.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The application should be easy to learn.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The application should be easy to discover unknown functionality.
                        </h4>
                    </li>
                    <li>
                        <h4>
                            The system must be efficient for the frequent user.
                        </h4>
                    </li>
                </ul>
            </Fieldset>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Requirements"
}
</script>

<style scoped>

.requirements-box {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.requirements-box-row {
    width: auto;
}

.dropdown-item {
    margin-top: 20px;
}

</style>