/**
* Created by Linfeng Li on 4/21/22
* University of Illinois Chicago
*/

<template>
    <div class="motivation">
        <Fieldset class="dropdown-item" legend="Motivation">
            The mental and physical effort it takes to remember a parked car’s location can be cumbersome. For example:
            taking a picture of the parking spot, remembering the spot in one’s head, writing down the spot, or saving
            the spot on a mobile device. These actions all require the user to allocate time and energy to achieve.
            Therefore, we want to identify a way to streamline the experience.
        </Fieldset>
    </div>

</template>

<script>

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Motivation",
    data() {
        return {};
    },
    methods: {},
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://platform.linkedin.com/badges/js/profile.js')
        document.head.appendChild(recaptchaScript)
    },
}
</script>

<style scoped>

.motivation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
}

</style>