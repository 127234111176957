/**
* Created by Linfeng Li on 4/13/22
* University of Illinois Chicago
*/

<template>
    <div class="demo-box">
        <div class="demo-row">
            <Fieldset class="dropdown-item" legend="Record Parking" >
                <LiteYouTubeEmbed
                    id="jSWtEhNl0FI"
                    title="Share Parking project demo video"
                />
            </Fieldset>

            <Fieldset class="dropdown-item" legend="Retrieve Parking" >
                <LiteYouTubeEmbed
                    id="O-BM14woRZk"
                    title="Share Parking project demo video"
                />
            </Fieldset>

            <Fieldset class="dropdown-item" legend="Share Parking" >
                <LiteYouTubeEmbed
                    id="_Zayo3HPrns"
                    title="Share Parking project demo video"
                />
            </Fieldset>
        </div>

    </div>
</template>

<script>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/dist/style.css'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Demo",
    components: { LiteYouTubeEmbed }
}
</script>

<style scoped>

.demo-box {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.demo-row {
    width: 60%;
    min-width: 300px;
}

.dropdown-item {
    margin-top: 20px;
}

</style>